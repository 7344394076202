import React from "react";

export default function CancellationAndRefundPolicy() {
  return (
    <div
      style={{
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 400,
        margin: "5% 10%",
        lineHeight: "1.6",
      }}
    >
      {/* Heading */}
      <h2
        style={{
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Cancellation and Refund Policy
      </h2>

      {/* Effective Date */}
      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        Effective Date: 19-02-2025
      </p>

      {/* Introduction */}
      <p>
        At <strong>Creativa Academy</strong>, we are committed to providing a
        high-quality learning experience. If you wish to request a cancellation
        or refund, please review our policy below:
      </p>

      {/* Section 1 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        1. Course Enrollment Cancellations & Refunds
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Users can avail a 100% refund if they cancel their course before the
          start date.
        </li>
        <li>
          Users can avail a 50% refund if they cancel their course within 7 days
          after the start date.
        </li>
        <li>
          No refunds will be processed for cancellation after 7 days from the
          course start date.
        </li>
      </ul>

      {/* Section 2 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        2. Subscription Cancellations
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Monthly and Annual subscriptions can be canceled before the next
          billing cycle to avoid future charges.
        </li>
        <li>
          No refunds will be provided for the remaining duration of an active
          subscription.
        </li>
      </ul>

      {/* Section 3 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        3. Refund Processing
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Refunds are processed within 7-10 business days to the original
          payment method.
        </li>
        <li>
          Transaction fees, if applicable, may be deducted from the refund
          amount.
        </li>
        <li>
          For any refund requests, contact{" "}
          <a
            href="mailto:enquiry@creativa.academy"
            style={{
              color: "#CEE531",
              textDecoration: "underline",
            }}
          >
            enquiry@creativa.academy
          </a>
        </li>
      </ul>

      {/* Closing Note */}
      <p>
        By enrolling in a course at <strong>Creativa Academy</strong>, you
        acknowledge and agree to the terms outlined in this Cancellation and
        Refund Policy.
      </p>
    </div>
  );
}
