import React from "react";

export default function PrivacyPolicy() {
  return (
    <div
      style={{
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 400,
        margin: "5% 10%",
        lineHeight: "1.6",
      }}
    >
      {/* Heading */}
      <h2
        style={{
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Privacy Policy
      </h2>

      {/* Effective Date */}
      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        Effective Date: 19-02-2025
      </p>

      {/* Introduction */}
      <p>
        At <strong>Creativa Academy</strong>, we are committed to protecting
        your privacy. This Privacy Policy outlines how we collect, use, and
        safeguard your personal information.
      </p>

      {/* Section 1 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        1. Information We Collect
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Personal details such as name, email, phone number, and address when
          you register or make a purchase.
        </li>
        <li>
          Payment information processed securely through third-party payment
          gateways.
        </li>
        <li>
          Browsing data, cookies, and analytics for improving user experience.
        </li>
      </ul>

      {/* Section 2 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        2. How We Use Your Information
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>To provide and enhance our services.</li>
        <li>For communication regarding courses, promotions, and updates.</li>
        <li>To ensure website security and prevent fraud.</li>
      </ul>

      {/* Section 3 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        3. Data Sharing & Security
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          We do not sell or share personal data with third parties except for
          service providers.
        </li>
        <li>Data is encrypted and stored securely.</li>
        <li>
          For any concerns, contact{" "}
          <a
            href="mailto:enquiry@creativa.academy"
            style={{
              color: "#CEE531",
              textDecoration: "underline",
            }}
          >
            enquiry@creativa.academy
          </a>
        </li>
      </ul>
    </div>
  );
}
