import React from "react";

export default function TermsAndConditions() {
  return (
    <div
      style={{
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 400,
        margin: "5% 10%",
        lineHeight: "1.6",
      }}
    >
      {/* Heading */}
      <h2
        style={{
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Terms and Conditions
      </h2>

      {/* Effective Date */}
      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        Effective Date: 19-02-2025
      </p>

      {/* Introduction */}
      <p>
        Welcome to <strong>Creativa Academy</strong>! By accessing our website
        and services, you agree to the following terms and conditions.
      </p>

      {/* Section 1 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        1. Use of Services
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>Users must provide accurate information during registration.</li>
        <li>
          Course materials are for personal use only and cannot be
          redistributed.
        </li>
      </ul>

      {/* Section 2 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        2. Payment & Subscriptions
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>Payments are processed securely via third-party gateways.</li>
        <li>Any applicable taxes are the user's responsibility.</li>
      </ul>

      {/* Section 3 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        3. Limitations of Liability
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Creativa Academy is not responsible for technical issues or losses due
          to misuse.
        </li>
        <li>Users must comply with ethical usage of the platform.</li>
        <li>
          For disputes, contact{" "}
          <a
            href="mailto:enquiry@creativa.academy"
            style={{
              color: "#CEE531",
              textDecoration: "underline",
            }}
          >
            enquiry@creativa.academy
          </a>
        </li>
      </ul>
    </div>
  );
}
