import React from "react";

export default function ShippingAndDeliveryPolicy() {
  return (
    <div
      style={{
        color: "white",
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 400,
        margin: "5% 10%",
        lineHeight: "1.6",
      }}
    >
      {/* Heading */}
      <h2
        style={{
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Shipping and Delivery Policy
      </h2>

      {/* Effective Date */}
      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "20px",
        }}
      >
        Effective Date: 19-02-2025
      </p>

      {/* Introduction */}
      <p>
        Thank you for choosing <strong>Creativa Academy</strong>! Since our
        platform provides digital services and online learning materials, we do
        not offer physical shipping. Below are the details regarding the
        availability of our digital products and services.
      </p>

      {/* Section 1 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        1. Digital Product Access
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Upon successful payment, users will receive immediate or scheduled
          access to purchased courses, learning materials, or services.
        </li>
        <li>
          Course access details will be sent to the registered email address.
        </li>
      </ul>

      {/* Section 2 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        2. Payment Confirmation & Processing
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Payments are processed securely through our third-party payment
          gateway (e.g., Razorpay).
        </li>
        <li>
          If you do not receive access within <strong>30 </strong> minutes after
          payment, please contact{" "}
          <a
            href="mailto:enquiry@creativa.academy"
            style={{
              color: "#CEE531",
              textDecoration: "underline",
            }}
          >
            enquiry@creativa.academy
          </a>{" "}
          with your payment details.
        </li>
      </ul>

      {/* Section 3 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        3. No Physical Delivery
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          Since Creativa Academy offers only digital services, no shipping is
          required.
        </li>
        <li>
          Users are responsible for ensuring they have proper internet access to
          use our platform.
        </li>
      </ul>

      {/* Section 4 */}
      <h3
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
        }}
      >
        4. Support & Assistance
      </h3>
      <ul style={{ paddingLeft: "20px", marginTop: "5px" }}>
        <li>
          If you face any issues accessing your course or digital content, reach
          out to us at{" "}
          <a
            href="mailto:enquiry@creativa.academy"
            style={{
              color: "#CEE531",
              textDecoration: "underline",
            }}
          >
            enquiry@creativa.academy
          </a>
        </li>
        <li>
          Our support team typically responds within <strong>24</strong> hours
          on business days.
        </li>
      </ul>

      <p>
        By making a purchase on <strong>Creativa Academy</strong>, you
        acknowledge and agree to this{" "}
        <strong>Shipping & Delivery Policy</strong>.
      </p>
    </div>
  );
}
